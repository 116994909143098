<template>
  <div class="fluid" id="masterjob">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>

    <v-row>
      <v-col
        cols="12"
        class="d-flex"
        style="
          position: relative;
          padding: 0 20px;
          text-align: center;
          margin-top: 20px;
        "
      >
        <h4 class="indigo--text" style="font-weight: bold; margin: auto">
          DATA PEKERJAAN
          <v-tooltip bottom color="grey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                text
                icon
                color="grey darken-2"
                @click="refreshTable"
                :loading="refreshingTable"
              >
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>refresh page</span>
          </v-tooltip>
        </h4>
      </v-col>
    </v-row>

    <div class="masterjob-list">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="
            position: relative;
            padding: 0 20px;
            text-align: center;
            margin-top: 20px;
          "
        >
          <div>
            <div style="display: flex; flex-direction: flex; gap: 8px">
              <v-btn
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size: 12px"
                @click="showOpenJob = true"
              >
                Tambah Pekerjaan
              </v-btn>
            </div>
            <v-dialog v-model="showOpenJob" max-width="400px">
              <v-card>
                <v-card-title class="headline indigo--text">
                  Tambah Pekerjaan
                </v-card-title>
                <v-card-text>
                  <v-form @submit.prevent="addJob">
                    <v-text-field
                      v-model="name"
                      label="Job"
                      required
                    ></v-text-field>
                    <v-btn type="submit" color="primary">Tambah</v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-snackbar v-model="showAlert" :color="alertType" top>
              {{ alertMessage }}
            </v-snackbar>
          </div>

          <div class="recruit-toolbar-1" style="width: 150px">
            <v-text-field
              v-model="searchKeyword"
              label="cari nama"
              type="search"
              outlined
              dense
              :append-icon="searchKeyword ? 'mdi-close-circle' : 'mdi-magnify'"
              style="margin-left: 10px"
              @click:append="clearSearch"
              @keyup.enter="search"
              :disabled="loading"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-col cols="12">
      <v-dialog v-model="dialog" max-width="1000">
        <v-card class="dialog-card">
          <v-toolbar color="primary" dark>
            <v-toolbar-title class="headline">
              Judul Lowongan Pekerjaan
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn icon @click="showConfirmDeleteData(selectedItem)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text v-if="selectedItem">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card class="pa-4" outlined>
                    <div class="text-left">
                      <h2 class="display-1">{{ selectedItem.name }}</h2>
                      <!-- <v-text-field v-model="selectedItem.name"></v-text-field> -->
                      <v-divider class="my-4"></v-divider>
                      <div class="subtitle-1">
                        <v-icon>mdi-account</v-icon> ID: {{ selectedItem.id }}
                      </div>
                      <div class="subtitle-1">
                        <v-icon>mdi-calendar</v-icon> Dibuat Pada:
                        {{ formattedDate(selectedItem.created_at) }}
                      </div>
                      <div class="subtitle-1">
                        <v-icon>mdi-calendar-edit</v-icon> Diperbarui Pada:
                        {{ formattedDate(selectedItem.updated_at) }}
                      </div>
                      <div class="subtitle-1">
                        <v-icon>mdi-check-circle</v-icon> Status:
                        <v-chip
                          :color="
                            selectedItem.status == 1 ? 'success' : 'error'
                          "
                          text-color="white"
                        >
                          {{
                            selectedItem.status == 1
                              ? 'Tersedia'
                              : 'Tidak Tersedia'
                          }}
                        </v-chip>
                      </div>
                      <v-btn
                        v-if="selectedItem.status == 0"
                        class="gantistatus"
                        color="primary"
                        @click="changeStatus(selectedItem.id, 1)"
                      >
                        Aktifkan Lowongan
                      </v-btn>
                      <v-btn
                        v-if="selectedItem.status == 1"
                        class="gantistatus"
                        color="primary"
                        @click="changeStatus(selectedItem.id, 0)"
                      >
                        Non Aktifkan Lowongan
                      </v-btn>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-data-table
        mobile-breakpoint="0"
        fixed-header
        height="50vh"
        :headers="headers"
        style="cursor: pointer; margin: 20px"
        :items="items"
        :loading="loading"
        @click:row="rowClick"
        item-key="id"
        :options.sync="options"
        :footer-props="{ showFirstLastPage: true, showCurrentPage: true }"
      >
        <template v-slot:[`item.status`]="{ item }">
          <div
            :style="`color: ${
              item.status == 1 ? 'green' : item.status == 0 ? 'red' : 'black'
            }`"
          >
            {{
              item.status == 1
                ? 'Tersedia'
                : item.status == 0
                ? 'Tidak Tersedia'
                : '-'
            }}
          </div>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">{{
          formattedDate(item.created_at)
        }}</template>
        <template v-slot:[`item.updated_at`]="{ item }">{{
          formattedDate(item.updated_at)
        }}</template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn @click.stop="showConfirmDeleteData(item)" color="red" x-small>
            <v-icon small color="white">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="confirmDeleteData" max-width="400px">
      <v-card>
        <v-card-title class="headline red--text">
          Konfirmasi Hapus
        </v-card-title>
        <v-card-text> Apakah Anda yakin ingin menghapus data ini? </v-card-text>
        <v-card-actions>
          <v-btn color="green" dark @click="eraseData(true)">Ya</v-btn>

          <v-btn color="red" dark @click="eraseData(false)">Batal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAlert: false,
      alertMessage: '',
      alertType: '',
      searchKeyword: '',
      loading: false,
      dialog: false,
      selectedItem: null,
      refreshingTable: false,
      options: {},
      items: [],
      showOpenJob: false,
      name: '',
      confirmDeleteData: false,
      headers: [
        { text: 'Pekerjaan', value: 'name', sortable: false },
        { text: 'Tgl. Buat', value: 'created_at', sortable: false },
        { text: 'Tgl. Update', value: 'updated_at', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Action', value: 'action', sortable: false }
      ]
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    refreshTable() {
      this.loading = true
      this.clearSearch()
    },
    fetchData(searchKeyword) {
      this.loading = true
      let url = 'http://localhost:3000/viewMasterJob'

      if (searchKeyword) {
        url += `?keyword=${encodeURIComponent(searchKeyword)}`
      }
      fetch(url)
        .then((res) => res.json())
        .then((res) => {
          setTimeout(() => {
            this.items = res
            this.loading = false
          }, 200)
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    search() {
      this.fetchData(this.searchKeyword)
    },
    clearSearch() {
      this.searchKeyword = ''
      this.fetchData()
    },
    rowClick(item) {
      this.showDetail(item)
    },
    showDetail(item) {
      this.selectedItem = item
      this.dialog = true
    },
    async addJob() {
      try {
        if (this.name == null || this.name == '') {
          return alert('Kolom nama tidak boleh kosong')
        }
        const response = await fetch('http://localhost:3000/masterJob/save', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ act: 'add', name: this.name }) // Menggunakan properti name
        })

        if (!response.ok) {
          throw new Error('Terjadi kesalahan saat menambahkan pekerjaan.')
        }

        const data = await response.json()
        console.log(data)
        this.name = ''
        this.showAlert = true
        this.alertMessage = 'Lowongan berhasil ditambahkan!'
        this.alertType = 'success'
        this.loading = true
        this.fetchData()
        this.showOpenJob = false
      } catch (error) {
        console.error(error)
        alert(
          'Terjadi kesalahan saat menambahkan pekerjaan. Silakan coba lagi.'
        )
      }
    },
    formattedDate(val) {
      let date = '-'
      if (val !== null) {
        const a = new Date(val)
        const b = a.toLocaleDateString('id')
        date = b
      }

      return date
    },
    showConfirmDeleteData(item) {
      console.log('delete clicked')
      this.selectedItem = item
      setTimeout(() => {
        this.confirmDeleteData = true
      }, 100)
    },
    async eraseData(confirm) {
      if (confirm) {
        const url = `http://localhost:3000/masterJob/${this.selectedItem.id}` // asumsi ada properti id pada item
        fetch(url, {
          method: 'DELETE'
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }

            this.confirmDeleteData = false
            this.showAlert = true
            this.alertMessage = 'Lowongan berhasil dihapus!'
            this.alertType = 'success'
            this.loading = true
            this.fetchData()
          })
          .catch((error) => {
            console.error(
              'There was a problem with the fetch operation:',
              error
            )
          })
      } else {
        this.confirmDeleteData = false
      }
    },
    changeStatus(id, status) {
      const url = 'http://localhost:3000/masterJob/save'
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          act: 'update',
          id: id,
          status: status,
          name: this.name
        })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          this.dialog = false
          this.loading = true
          this.fetchData()
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#masterjob {
  position: relative;
  .masterjob-list {
    margin: 10px 20px;
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .recruit-toolbar-1 {
      margin-left: auto;
    }
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid rgba(255, 255, 255, 0.2);
  border-top: 16px solid #2e2be2;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gantistatus {
  margin-top: 20px;
  width: 100%;
}
</style>
